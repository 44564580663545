<template>
  <main :id="`custom_${form_id}`" class="main_wrapper">
    <div class="title" v-if="title">{{ title }}</div>
    <i
      v-if="!open && burger"
      @click="toggle"
      class="accordion fa-solid fa-bars"
    ></i>

    <Drawer :closeable="true">
      <div v-if="open">
        <i @click="toggle" class="closed fa-solid fa-xmark"></i>
        <img
          alt="logo"
          @click="toggle"
          style="max-width: 200px; cursor: pointer"
          class="logo desktop"
          :src="`${logo}`"
        />
        <ul>
          <li @click="toggle">
            <a @click="view = 'form'">Formulaire</a>
          </li>
          <li @click="toggle" v-if="data.reglement_uri">
            <a rel="noopener" :href="`${data.reglement_uri}`" target="_blank"
              >Règlement</a
            >
          </li>
          <li @click="toggle" v-if="data.modalites_uri">
            <a rel="noopener" :href="`${data.modalites_uri}`" target="_blank"
              >Modalités</a
            >
          </li>
          <li @click="toggle" v-if="data.politique_uri">
            <a rel="noopener" :href="`${data.politique_uri}`" target="_blank"
              >Politique de confidentialité</a
            >
          </li>
          <li @click="toggle" v-if="data.mentionsLegales">
            <a @click="view = 'contact'">Contact</a>
          </li>
          <li @click="toggle" v-if="data.contactezNous">
            <a @click="view = 'mentions'">Mentions légales</a>
          </li>
        </ul>
      </div>
    </Drawer>

    <section v-if="wait" class="popup">
      <div class="popup-content">
        <div class="spinner-container">
          <div class="spinner"></div>
        </div>
        <h2>Envoi en cours</h2>
        <p>Merci de bien vouloir patienter...</p>
      </div>
    </section>

    <section v-if="err" class="popup">
      <div class="popup-content">
        <i @click="err = false" class="close fa-solid fa-xmark"></i>
        <h2>Une erreur est survenue</h2>
        <p>
          Merci de bien vouloir recontacter le
          support technique.
        </p>
      </div>
    </section>

    <section v-if="err2" class="popup">
      <div class="popup-content">
        <h2>Une erreur est survenue</h2>
        <p>
          Votre code semble invalide. Merci de bien vouloir recontacter le
          support technique.
        </p>
      </div>
    </section>

    <section v-if="ok" class="popup">
      <div class="popup-content">
        <p
          v-if="data.custom.text_submited"
          v-html="data.custom.text_submited"
        />
        <p style="text-align: left" v-else>
          <span v-if="updateid == '64e8a5bd48dd42966b5eca5a'">
            Tu información ha sido tomada en cuenta.<br /><br />
            Esperamos sus elementos enviados por correo.<br /><br />
          </span>
          <p  v-else-if="form_id == '66168433660a3eb0a82b8d33'"><b>Merci</b><br><br>Votre compte est en cours de validation par nos équipes. il sera actif en 48h ouvrées si l’ensemble des éléments que vous nous avez transmis sont correct.</p>
          <span v-else>
            Vos informations ont bien été prises en compte.<br /><br />
            Nous attendons vos éléments adressés par courrier.<br /><br />
          </span>
          Merci<br />
        </p>
      </div>
    </section>

    <section v-if="okstatus" class="popup">
      <div class="popup-content">
        <i @click="reloadStatus()" class="close fa-solid fa-xmark"></i>
        <p
          style="text-align: left"
          v-if="updateid == '64e8a5bd48dd42966b5eca5a'"
        >
          Tu información ha sido tomada en cuenta.<br /><br />
          Gracias
        </p>
        <p v-else style="text-align: left">
          Vos informations ont bien été prises en compte.<br /><br />
          Merci
        </p>
      </div>
    </section>

    <div v-if="form_id && view === 'form'" class="formob">
      <div v-if="logo" class="logo"><img :src="`${logo}`" /></div>
      <section
        class="title"
        v-if="data.custom.text_home"
        v-html="data.custom.text_home"
      />

      <section class="code" v-if="askForCode">
        <form @submit.prevent="askCodeFormSubmit">
          <div id="askcode_element">
            <label for="askcode"
              >Votre code<span class="required-star"> *</span></label
            >
            <input
              v-model="askedcode"
              id="askcode"
              name="Votre code"
              required="required"
              type="text"
              class=""
              aria-required="true"
            />
          </div>
          <p class="mention">* Champs obligatoires</p>
          <div class="builder-buttons">
            <button type="submit" id="next_button" class="valider">
              Valider
            </button>
          </div>
        </form>
      </section>

      <Form
        :class="'form_step' + step"
        id="monform"
        :form_id="form_id"
        :server="server"
        v-on:results="getResult"
        v-on:step-up="checkStep"
        v-on:step-down="checkStep"
        v-on:form_submit_loading="loading"
        @on_error="viewError"
        :hideError="true"
        :relay="{ codes: [code], ...$route.query }"
        v-else
      />

      <div class="captcha">
        This site is protected by reCAPTCHA and the Google<br />
        <a href="https://policies.google.com/privacy" target="_blank"
          >Privacy Policy</a
        >
        and
        <a href="https://policies.google.com/terms" target="_blank"
          >Terms of Service</a
        >
        apply.
      </div>
    </div>

    <div v-if="view === 'mentions'" class="formob">
      <div v-if="logo" @click="view = 'form'" class="logo">
        <img :src="`${logo}`" />
      </div>
      <section class="title">
        <div class="markdown" v-html="data.mentionsLegales"></div>
      </section>
    </div>

    <div v-if="view === 'contact'" class="formob">
      <div v-if="logo" @click="view = 'form'" class="logo">
        <img :src="`${logo}`" />
      </div>
      <section class="title">
        <div class="markdown contact" v-html="data.contactezNous"></div>
      </section>
    </div>

    <div v-if="view === 'status'">
      <div v-if="logo" class="logo">
        <img :src="`${logo}`" />
      </div>
      <section class="title">
        <div v-if="status_form_id" class="status">
          <Form
            id="form"
            :form_id="status_form_id"
            :uid="this.$route.params._secu"
            :submission_id="this.$route.params._sub"
            :server="server"
            v-on:results="getResultStatus"
            v-on:form_submit_loading="loading"
            @on_error="viewError"
            :hideError="hiderror"
          />
        </div>
      </section>
    </div>
    <span v-if="style" v-html="style"></span>
  </main>
</template>
<script>
import axios from "axios";
import Drawer from "vue-simple-drawer";

export default {
  name: "form",
  data: function () {
    return {
      title: undefined,
      updateid: undefined,
      burger: true,
      view: "form",
      wait: false,
      err: false,
      err2: false,
      ok: false,
      style: undefined,
      redirectsaisie: false,
      okstatus: false,
      open: false,
      fontID: "Roboto",
      hiderror: true,
      server: process.env.VUE_APP_ROOT_API,
      form_id: undefined,
      status_form_id: undefined,
      logo: undefined,
      step: 0,
      askForCode: false,
      askedcode: "",
      gameid: this.$route.params._id,
      code: this.$route.params._code,
      operationId: "",
      data: {
        custom: {
          lotperdu_informations:
            "Nous avons bien pris en compte vos informations.",
          fontID: '"Arial", sans-serif',
          primary_color_bg: "#FFFFFF",
          primary_color_txt: "#444444",
          secondary_color_bg: "#444444",
          secondary_color_txt: "#FFFFFF",
          color_top_link: "#444444",
          color_input_border: "#444444",
          color_error_txt: "#FF00FF",
          color_input_bg: "#FFFFFF",
          color_input_txt: "#444444",
          color_input_bg_focus: "#444444",
          color_input_bg_error: "#FF00FF",
          input_txt_error: "#FF00FF",
          border_radius_popup: "5px",
          border_radius_buttons: "5px",
          border_radius_field: "5px",
          color_bg_button_back: "#444444",
          color_text_button_back: "#FFFFFF",
        },
      },
    };
  },
  components: {
    Drawer,
  },
  async created() {
    console.log(this.$route.params);
  },
  async mounted() {
    if (this.$route.query.menu === "off") {
      this.burger = false;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const createid = this.$route.query.create;
    this.updateid = this.$route.query.update;

    if (this.updateid) {
      this.view = "status";
      this.status_form_id = this.updateid;
      this.$route.params._secu = this.$route.query._secu;
      this.$route.params._sub = this.$route.query._sub;
    }

    this.redirectsaisie = urlParams.get("redirectsaisie") == "true";

    try {
      if (this.$route.params._sub) {
        this.view = "status";
      }
      let { data } = await this.getData();
      this.operationId = data._id;
      if (!this.code) this.code = "";

      if (data.custom.custom_css) {
        this.style = "<style>" + data.custom.custom_css + "</style>";
      }

      if (data.files) {
        var file1 = data.files.filter(function (file) {
          return file.filename.includes("politique");
        });
        if (file1 && file1.length > 0) {
          data.politique_uri = file1[0].uri;
        }

        var file2 = data.files.filter(function (file) {
          return file.filename.includes("modalites");
        });
        if (file2 && file2.length > 0) {
          data.modalites_uri = file2[0].uri;
        }

        var file3 = data.files.filter(function (file) {
          return file.filename.includes("reglement");
        });
        if (file3 && file3.length > 0) {
          data.reglement_uri = file3[0].uri;
        }
      }

      let createForm = data.forms.find((form) => {
        return form.type === "CREATE";
      });

      if (createid) {
        createForm._id = createid;
      }

      let { data: formulaire } = await axios.get(
        `${process.env.VUE_APP_ROOT_API}/api/v2/formulaires/${createForm._id}`
      );

      formulaire = formulaire.formulaire;
      let realop = await this.getDataOp(formulaire.operationId);
      this.title = realop.title;
      console.log("formulaire.numberOfCodes", formulaire.numberOfCodes);

      if (formulaire.numberOfCodes > 0 && this.$route.params._code) {
        if (!(await this.codeCheck(this.$route.params._code))) {
          this.viewError2();
        }
      } else if (formulaire.numberOfCodes > 0) {
        this.askForCode = true;
      } else {
        this.askForCode = false;
      }

      if (data.custom && data.custom.pagetitle)
        document.querySelector("title").textContent =
          data.custom.pagetitle.replace(/(<([^>]+)>)/gi, "");

      /* GESTION DU FAVICON */
      if (data.files) {
        var favicon = data.files.filter((s) => s.filename.includes("favicon"));
        if (favicon && favicon.length > 0) {
          var link = document.querySelector("link[rel='icon']");
          link.href = favicon[0].uri;
        }
      }

      if (data.forms) {
        var status = data.forms.filter((s) => {
          return s.type === "UPDATE";
        });
        if (status && status.length) {
          this.status_form_id = status[0]._id;
        }

        var create = data.forms.filter((f) => {
          return f.type === "CREATE";
        });

        if (create && create.length) {
          this.data = data;
          this.form_id = create[0]._id;

          var logo = data.files.filter((s) => s.filename.includes("logo1"));
          if (logo && logo.length > 0) {
            this.logo = logo[0].uri;
          }
          this.setStyle();
        }
      }
    } catch (e) {
      this.setStyle();
      this.viewError();
    }
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    async codeCheck(code) {
      const codesCheck = await axios.post(
        `${process.env.VUE_APP_ROOT_API}/api/v2/codechecker`,
        {
          operationId: this.operationId,
          codes: [code.toUpperCase()],
        }
      );
      return codesCheck.data.valid;
    },
    async askCodeFormSubmit() {
      if (await this.codeCheck(this.askedcode)) {
        this.code = this.askedcode;
        this.askForCode = false;
      } else {
        this.viewError2();
      }
    },
    formatToHex(hex) {
      if (hex) {
        var new_hex = hex.replace("#", "");
        return "#" + new_hex;
      }
    },
    reloadStatus() {
      document.location.href = `/${this.$route.params._id}/status/${this.$route.params._sub}/${this.$route.params._secu}`;
    },
    setStyle() {
      if (this.data.custom.font_family)
        this.fontID = this.data.custom.font_family;

      var head = document.getElementsByTagName("head")[0];

      if (this.data.files && this.data.files.length) {
        var favicon = this.data.files.filter((s) =>
          s.filename.includes("favicon")
        );
        if (favicon && favicon.length > 0) {
          var link2 = document.querySelector("link[rel='icon']");
          link2.href = favicon[0].uri + "?";
        }
      }

      if (!this.data.custom.primary_color_bg)
        this.data.custom.primary_color_bg = "#FFFFFF";
      if (!this.data.custom.primary_color_txt)
        this.data.custom.primary_color_txt = "#000000";

      if (
        this.fontID != "AmpleSoft Pro" &&
        this.fontID != "Handelson Four" &&
        this.fontID != "MSans" &&
        this.fontID != "Museo Slab" &&
        this.fontID != "Qanelas" &&
        this.fontID != "SantElia Script" &&
        this.fontID != "Blackore" &&
        this.fontID != "Argone" &&
        this.fontID != "CreamyScript" &&
        this.fontID != "Impact" &&
        this.fontID != "Montserrat"
      ) {
        var head = document.getElementsByTagName("head")[0];
        var link = document.createElement("link");
        link.id = this.fontID;
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href =
          "https://fonts.googleapis.com/css?family=" +
          this.fontID +
          ":wght@300;400;500;700;900&display=swap";
        link.media = "all";
        head.appendChild(link);
      }

      const style = document.createElement("style");
      style.appendChild(
        document.createTextNode(
          `:root {
            --body-font-size : ${this.data.custom.font_size}px;
            --body-fonts: ${this.fontID};
            --primary-color-txt : ${this.formatToHex(
              this.data.custom.primary_color_txt
            )};
            --secondary-color : ${this.formatToHex(
              this.data.custom.secondary_color_bg
            )};
            --secondary-color-txt : ${this.formatToHex(
              this.data.custom.secondary_color_txt
            )};
            --primary-color-bg : ${this.formatToHex(
              this.data.custom.primary_color_bg
            )};
            --color-top-link : ${this.formatToHex(
              this.data.custom.color_top_link
            )};
            --color-input-border :${this.formatToHex(
              this.data.custom.color_input_border
            )};
            --color-input-bg :${this.formatToHex(
              this.data.custom.color_input_bg
            )};
            --color-input-txt :${this.formatToHex(
              this.data.custom.color_input_txt
            )};
            --color-input-bg-focus :${this.formatToHex(
              this.data.custom.color_input_bg_focus
            )};
            --color-input-txt-error: ${this.formatToHex(
              this.data.custom.input_txt_error
            )};
            --color-input-bg-error:${this.formatToHex(
              this.data.custom.color_input_bg_error
            )};
            --color-error-txt:${this.formatToHex(
              this.data.custom.color_error_txt
            )};
            --border-radius-popup : ${this.data.custom.border_radius_popup}px;
            --border-radius-button : ${
              this.data.custom.border_radius_buttons
            }px;
            --border-radius-field : ${this.data.custom.border_radius_field}px;
            --color-bg-button-back : ${this.formatToHex(
              this.data.custom.color_bg_button_back
            )};
            --color-text-button-back : ${this.formatToHex(
              this.data.custom.color_text_button_back
            )};
            --color-bg-valid-check : ${this.formatToHex(
              this.data.custom.color_bg_valid_check
            )};
        }`
        )
      );
      document.head.appendChild(style);
    },
    getData() {
      return axios.get(
        `${process.env.VUE_APP_ROOT_API}/api/formdata/gameinfos/${this.gameid}`,
        { withCredentials: true }
      );
    },
    async getDataOp(operationId) {
      const { data } = await axios.get(
        `${process.env.VUE_APP_ROOT_API}/api/formdata/${operationId}`
      );
      return data;
    },
    loading(value) {
      if (value === true) {
        this.wait = true;
      }
    },
    checkStep() {
      this.step = this.step++;
    },
    getResultStatus(result) {
      if (result._id) {
        this.wait = false;
        this.okstatus = true;
      } else {
        this.wait = false;
        alert("Erreur lors de l'envoi de vos données");
      }
    },
    getResult(result) {
      if (window.parent) {
        const message = {
          type: "form-submission",
          payload: result,
        };

        window.parent.postMessage(message, "*");
      }
      if (window.redirection) {
        window.location.replace(window.redirection);
      } else {
        if (result._id) {
          if(this.form_id == "66168433660a3eb0a82b8d33"){
            this.$router.push("/sncf");
            return;
          }

          this.wait = false;
          this.ok = true;
          if (this.redirectsaisie) {
            window.open(
              `https://saisie.promo.dev/${result.operationId}/${result._id}`,
              "_blank"
            );
          }
        } else {
          this.wait = false;
          alert("Erreur lors de l'envoi de vos données");
        }
      }
    },
    viewError() {
      this.wait = false;
      this.err = true;
    },
    viewError2() {
      this.wait = false;
      this.err2 = true;
    },
  },
};
</script>

<style lang="scss">
.upload-btn-wrapper {
  button ~ input[type="file"] {
    font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width:350px;
}

.upload-btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}


.valider {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.title {
  width: 100%;
  text-align: center;
  padding: 5px;
  background-color: black;
  color: white;
}
</style>
